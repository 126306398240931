import { useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { spTrackVideo } from "~/utils/tracking";

export type WistiaProps = {
	wistiaId: string;
};

function roundUpToNearestPercentage(percentWatched: number) {
	const percentagesArr = [25, 50, 75, 100];
	let progressPercentage = 0;
	let i = 0;

	while (percentagesArr[i] <= percentWatched) {
		i += 1;
		progressPercentage = percentagesArr[i];
	}

	return i > percentagesArr.length - 1
		? percentagesArr[percentagesArr.length - 1]
		: progressPercentage;
}

export function Wistia({ wistiaId }: WistiaProps) {
	const [name, setName] = useState<string>("");
	const [duration, setDuration] = useState<number>(0);
	const [currentTime, setCurrentTime] = useState<number>(0); // in seconds
	const [videoPercentage, setVideoPercentage] = useState<number>(0);

	const url = `https://fast.wistia.com/embed/${wistiaId}`;

	const handleWistiaEvent = (state: "playing" | "paused" | "ended") => {
		spTrackVideo({
			state: currentTime === 0 ? "start" : state,
			title: name,
			url: url,
			duration: duration,
			currentTime,
			percentage: roundUpToNearestPercentage(videoPercentage),
		});
	};

	const handleReady = (player: ReactPlayer) => {
		const name = player.getInternalPlayer().name();
		const duration = player.getDuration();
		setName(name);
		setDuration(duration);
	};

	const playerRef = useRef<ReactPlayer>(null);

	const opts = {
		wistia: {
			options: {
				playerColor: "#3545be",
				hashedId: wistiaId,
				plugin: {
					videoThumbnail: {
						on: true,
						hashedId: wistiaId,
						async: false,
					},
				},
			},
		},
	};

	return (
		<ReactPlayer
			url={url}
			onReady={handleReady}
			onPlay={() => handleWistiaEvent("playing")}
			onPause={() => handleWistiaEvent("paused")}
			onEnded={() => handleWistiaEvent("ended")}
			ref={playerRef}
			onProgress={(state) => {
				setCurrentTime(state.playedSeconds);
				setVideoPercentage(state.played * 100);
			}}
			config={opts}
			controls={true}
			className="aspect-video rounded-lg bg-black"
			width="100%"
			height="100%"
		/>
	);
}
