import clsx from "clsx";
import { Container } from "~/components/ui/container";
import type { Cta } from "~/types/index";
import { Paragraph } from "~/components/ui/typography";
import type { sizes } from "~/components/ui/typography";
import { CtaButtonGroup } from "~/components/ui/button";
import { HostProvider } from "~/components/hero/host-provider";
import type { Logo } from "~/components/logo-list";
import { BlockContent } from "~/components/block-content";
import type {
	CaptionImage,
	SimplePortableText,
	TitleImage,
} from "~/types/sanity-schema";
import { Youtube } from "~/components/youtube";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { ImageItem } from "~/components/image";
import Carousel from "~/components/carousel/carousel";
import React, { type JSX } from "react";
import { Video } from "~/components/ui/video";
import { Wistia } from "~/components/wistia";

interface CommonHeroProps {
	isTitleMultiColor?: boolean;
	breadcrumb?: JSX.Element;
	tagline?: string | React.ReactNode;
	title: string;
	titleSize?: keyof typeof sizes;
	description?: SimplePortableText;
	hostProviders?: Logo[];
	hideHostProviderTitle?: boolean;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
}

interface DefaultHeroProps extends CommonHeroProps {
	heroHasVideo?: boolean;
	imageSize?: "default" | "small" | "large";
	videoHosting?: "wistia" | "youtube";
	wistiaId?: string;
	ytId?: string;
	image?: ImageProps;
}

interface CarouselHeroProps extends CommonHeroProps {
	carouselItems?: CaptionImage[];
	carouselSyncTitleItems?: TitleImage[];
}

function CompactHero({
	breadcrumb,
	tagline,
	isTitleMultiColor,
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
}: CommonHeroProps) {
	return (
		<Container className={clsx({ "!pt-layout2": breadcrumb })}>
			{breadcrumb}
			<div className="mx-auto max-w-[1000px] text-center">
				{tagline ? (
					<Paragraph size="overline" className="mb-5" color="secondary">
						{tagline}
					</Paragraph>
				) : null}
				{isTitleMultiColor ? (
					<BlockContent
						value={description}
						blockOverrides={{
							h1: ({ children }) => (
								<Paragraph
									as="h1"
									size={titleSize ? titleSize : "heading-1"}
									fontWeight="font-semibold"
								>
									{children}
								</Paragraph>
							),
							normal: () => "",
						}}
					/>
				) : (
					<Paragraph
						as="h1"
						size={titleSize ? titleSize : "heading-1"}
						fontWeight="font-semibold"
					>
						{title}
					</Paragraph>
				)}
				<div className="animate-heroBounceUp opacity-0">
					{description ? (
						typeof description === "string" ? (
							<Paragraph size="body-large" color="secondary" className="my-6">
								{description}
							</Paragraph>
						) : (
							<BlockContent
								value={description}
								blockOverrides={{
									h1: () => "",
									normal: ({ children }) => (
										<Paragraph
											size="body-large"
											className="mt-6"
											color="secondary"
										>
											{children}
										</Paragraph>
									),
								}}
							/>
						)
					) : null}
					{hostProviders ? (
						<HostProvider
							logoItems={hostProviders}
							variant="compact"
							hideTitle={hideHostProviderTitle}
						/>
					) : null}

					{(primaryCTA && primaryCTA?.url) ||
					(secondaryCTA && secondaryCTA?.url) ? (
						<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center">
							<CtaButtonGroup
								primaryCTA={primaryCTA}
								secondaryCTA={secondaryCTA}
							/>
						</div>
					) : null}
				</div>
			</div>
		</Container>
	);
}

function DefaultHero({
	breadcrumb,
	tagline,
	primaryCTA,
	secondaryCTA,
	heroHasVideo = false,
	imageSize = "default",
	isTitleMultiColor,
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	ytId,
	videoHosting,
	wistiaId,
	image,
}: DefaultHeroProps) {
	const isImageLarge = imageSize == "large";
	const isImageSmall = imageSize == "small";

	return (
		<Container className={clsx({ "!pt-layout2": breadcrumb })}>
			{breadcrumb}
			<div className="flex flex-col gap-layout4 lg:flex-row lg:items-center">
				<div
					className={clsx("text-center lg:w-1/2 lg:text-left", {
						"lg:w-2/3":
							(!heroHasVideo && image?.src && isImageSmall) || !image?.src,
					})}
				>
					{tagline ? (
						typeof tagline === "string" ? (
							<Paragraph size="overline" className="mb-5" color="secondary">
								{tagline}
							</Paragraph>
						) : (
							tagline
						)
					) : null}
					{isTitleMultiColor ? (
						<BlockContent
							value={description}
							blockOverrides={{
								h1: ({ children }) => (
									<Paragraph
										as="h1"
										size={titleSize ? titleSize : "heading-1"}
										fontWeight="font-semibold"
									>
										{children}
									</Paragraph>
								),
								normal: () => "",
							}}
						/>
					) : (
						<Paragraph
							as="h1"
							size={titleSize ? titleSize : "heading-1"}
							fontWeight="font-semibold"
						>
							{title}
						</Paragraph>
					)}
					<div className="animate-heroBounceUp opacity-0">
						{description ? (
							typeof description === "string" ? (
								<Paragraph size="body-large" color="secondary" className="my-6">
									{description}
								</Paragraph>
							) : (
								<BlockContent
									value={description}
									blockOverrides={{
										h1: () => "",
										normal: ({ children }) => (
											<Paragraph
												size="body-large"
												className="mt-6"
												color="secondary"
											>
												{children}
											</Paragraph>
										),
									}}
								/>
							)
						) : null}
						{hostProviders ? (
							<HostProvider
								logoItems={hostProviders}
								variant="default"
								hideTitle={hideHostProviderTitle}
							/>
						) : null}

						{(primaryCTA && primaryCTA?.url) ||
						(secondaryCTA && secondaryCTA?.url) ? (
							<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center lg:justify-start">
								<CtaButtonGroup
									primaryCTA={primaryCTA}
									secondaryCTA={secondaryCTA}
								/>
							</div>
						) : null}
					</div>
				</div>
				{image?.src || (heroHasVideo && (ytId || wistiaId)) ? (
					<div
						className={clsx(
							"animate-heroBounceDown opacity-0 lg:mx-auto lg:w-1/2 lg:place-content-center",
							{
								"max-w-full overflow-visible 2xl:mr-[-124px] 2xl:w-[calc(50%+100px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(50%+200px)]":
									!heroHasVideo && isImageLarge,
								"lg:w-1/3": !heroHasVideo && isImageSmall,
							}
						)}
					>
						{heroHasVideo ? (
							videoHosting === "wistia" && wistiaId ? (
								<Wistia wistiaId={wistiaId} />
							) : videoHosting === "youtube" && ytId ? (
								<Youtube id={ytId} loading="eager" />
							) : null
						) : image?.src ? (
							<Image
								className={clsx("mx-auto my-0 mb-6 w-full lg:mb-0 lg:w-auto", {
									"sm:max-w-[400px]": isImageSmall,
								})}
								loading="eager"
								fetchpriority="high"
								{...image}
							/>
						) : null}
					</div>
				) : null}
			</div>
		</Container>
	);
}

function CarouselHero({
	breadcrumb,
	tagline,
	title,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	carouselItems,
}: CarouselHeroProps) {
	return (
		<Container compactPadding>
			<div className="flex flex-col gap-layout4 lg:flex-row">
				<div className="flex flex-col lg:w-1/2">
					{breadcrumb}
					<div className="flex h-full animate-heroBounceUp flex-col justify-center text-center opacity-0 lg:py-layout2 lg:text-left">
						{tagline ? (
							<Paragraph size="overline" className="mb-5" color="secondary">
								{tagline}
							</Paragraph>
						) : null}

						{title ? (
							<Paragraph as="h1" size="heading-1" fontWeight="font-semibold">
								{title}
							</Paragraph>
						) : null}

						{description ? (
							typeof description === "string" ? (
								<Paragraph size="body-large" color="secondary" className="my-6">
									{description}
								</Paragraph>
							) : (
								<BlockContent
									value={description}
									blockOverrides={{
										h1: ({ children }) => (
											<Paragraph
												as="h1"
												size="heading-1"
												fontWeight="font-semibold"
											>
												{children}
											</Paragraph>
										),
										normal: ({ children }) => (
											<Paragraph
												size="body-large"
												className="mt-6"
												color="secondary"
											>
												{children}
											</Paragraph>
										),
									}}
								/>
							)
						) : null}
						{hostProviders ? (
							<HostProvider
								logoItems={hostProviders}
								variant="default"
								hideTitle={hideHostProviderTitle}
							/>
						) : null}

						{(primaryCTA && primaryCTA?.url) ||
						(secondaryCTA && secondaryCTA?.url) ? (
							<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center lg:justify-start">
								<CtaButtonGroup
									primaryCTA={primaryCTA}
									secondaryCTA={secondaryCTA}
								/>
							</div>
						) : null}
					</div>
				</div>
				<div className="mx-auto max-w-full justify-center overflow-visible lg:w-1/2 lg:place-content-center 2xl:mr-[-124px] 2xl:w-[calc(50%+100px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(50%+200px)]">
					{carouselItems ? (
						<div
							className="rounded-lg p-layout2 lg:p-layout4"
							style={{
								backgroundColor: "rgba(227, 233, 255, 0.95)",
							}}
						>
							<Carousel
								autoplay={true}
								autoplaySpeed={5000}
								adaptiveHeight={true}
								arrows={false}
								fade={true}
								dotsClass="carousel-dot-container left flex"
								appendDots={(dots) => <ul>{dots}</ul>}
							>
								{carouselItems.map((item, i) => {
									const { image } = item;
									const sliderImg = image as ImageItem;
									return (
										<div key={i}>
											<Image
												className="mb-5 w-full rounded-lg"
												loading={i === 0 ? "eager" : "lazy"}
												fetchpriority={i === 0 ? "high" : "auto"}
												{...sliderImg}
											/>
											<div className="lg:max-w-content">
												<BlockContent
													value={item.caption}
													headingClassName="!text-grey-100"
													paragraphClassName="!text-grey-80"
												/>
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
					) : null}
				</div>
			</div>
		</Container>
	);
}

function Title({
	title,
	carouselSyncTitleItems,
	activeSlide,
}: {
	title: string;
	activeSlide: number;
	carouselSyncTitleItems?: TitleImage[];
}) {
	const splittedTitle = title.split("{slideTitle}");

	if (!title) return null;
	if (!carouselSyncTitleItems)
		return (
			<Paragraph as="h1" size="heading-1" fontWeight="font-semibold">
				{title}
			</Paragraph>
		);
	if (splittedTitle.length === 1) {
		return (
			<Paragraph
				as="h1"
				size="heading-1-article"
				fontWeight="font-semibold"
				color="primary"
			>
				{title}

				<span
					key={activeSlide}
					className={clsx("block animate-[fade_1.5s_ease-in-out]")}
				>
					<span className="inline-block !text-primary-80">
						{carouselSyncTitleItems[activeSlide].title}
					</span>
				</span>
			</Paragraph>
		);
	} else {
		return (
			<Paragraph
				as="h1"
				size="heading-1"
				fontWeight="font-semibold"
				color="primary"
			>
				{splittedTitle[0]}

				<span
					key={activeSlide}
					className={clsx("block animate-[fade_1.5s_ease-in-out]")}
				>
					<span className="inline-block !text-primary-80">
						{carouselSyncTitleItems[activeSlide].title}
					</span>
				</span>
				{splittedTitle[1]}
			</Paragraph>
		);
	}
}

function CarouselSyncTitleHero({
	breadcrumb,
	tagline,
	title,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	carouselSyncTitleItems,
}: CarouselHeroProps) {
	const [activeSlide, setActiveSlide] = React.useState(0);

	return (
		<Container className={clsx({ "!pt-layout2": breadcrumb })}>
			{breadcrumb}
			<div className="flex flex-col gap-layout3 text-center lg:flex-row lg:items-center lg:text-left">
				<div className="lg:w-[58%]">
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : null}
					<Title
						title={title}
						carouselSyncTitleItems={carouselSyncTitleItems}
						activeSlide={activeSlide}
					/>

					<div className="hidden lg:block">
						{hostProviders ? (
							<HostProvider
								logoItems={hostProviders}
								variant="default"
								hideTitle={hideHostProviderTitle}
							/>
						) : null}
						{(primaryCTA && primaryCTA?.url) ||
						(secondaryCTA && secondaryCTA?.url) ? (
							<div className="mt-6 flex gap-5">
								<CtaButtonGroup
									primaryCTA={primaryCTA}
									secondaryCTA={secondaryCTA}
								/>
							</div>
						) : null}
					</div>
				</div>

				<div className="mx-auto max-w-full overflow-visible lg:w-[calc(42%-24px)] lg:place-content-center 2xl:mr-[-100px] 2xl:w-[calc(42%+80px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(42%+160px)]">
					{carouselSyncTitleItems ? (
						<div>
							<Carousel
								autoplay={true}
								autoplaySpeed={3000}
								adaptiveHeight={true}
								arrows={false}
								dots={false}
								cssEase="linear"
								waitForAnimate={true}
								fade={true}
								infinite={true}
								beforeChange={(_current, next) => {
									setActiveSlide(next);
								}}
							>
								{carouselSyncTitleItems.map((item, i) => {
									const { image } = item;
									const sliderImg = image as ImageItem;

									return (
										//ReactFragment removes inline style width: 100%; display: inline-block;
										<React.Fragment key={i}>
											<div
												className={clsx(
													"flex size-full max-w-full items-center justify-center text-center",
													{
														"animate-[fade_1.5s_ease-in-out]": i == activeSlide,
													}
												)}
											>
												<Image
													className="max-w-full"
													loading={i === 0 ? "eager" : "lazy"}
													fetchpriority={i === 0 ? "high" : undefined}
													{...sliderImg}
												/>
											</div>
										</React.Fragment>
									);
								})}
							</Carousel>
						</div>
					) : null}
				</div>
				<div className="block lg:hidden">
					{hostProviders ? (
						<HostProvider
							logoItems={hostProviders}
							variant="default"
							hideTitle={hideHostProviderTitle}
						/>
					) : null}
					{(primaryCTA && primaryCTA?.url) ||
					(secondaryCTA && secondaryCTA?.url) ? (
						<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center">
							<CtaButtonGroup
								primaryCTA={primaryCTA}
								secondaryCTA={secondaryCTA}
							/>
						</div>
					) : null}
				</div>
			</div>
		</Container>
	);
}
interface HeroProps {
	tagline?: string | React.ReactNode;
	title: string;
	description?: SimplePortableText;
	titleSize?: keyof typeof sizes;
	image?: ImageProps;
	videoHosting?: "wistia" | "youtube";
	wistiaId?: string;
	ytId?: string;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	variant?:
		| "default"
		| "compact"
		| "carousel"
		| "carouselSyncTitle"
		| "bgVideo";
	imageSize?: "default" | "small" | "large";
	isDarkTheme?: boolean;
	backgroundColor?: string;
	backgroundImage?: string;
	backgroundPosition?: string;
	backgroundSize?: string;
	backgroundVideo?: string;
	hostProviders?: Logo[];
	hideHostProviderTitle?: boolean;
	breadcrumb?: JSX.Element;
	heroHasVideo?: boolean;
	roundedCorners?: string;
	carouselItems?: CaptionImage[];
	carouselSyncTitleItems?: TitleImage[];
	isTitleMultiColor?: boolean;
}

interface BgVideoHeroProps extends CommonHeroProps {
	backgroundVideo?: string;
}
function BgVideoHero({
	breadcrumb,
	tagline,
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	backgroundVideo,
}: BgVideoHeroProps) {
	return (
		<div className="dark relative bg-black">
			<Video
				src={backgroundVideo}
				controls={false}
				loop
				muted
				autoPlay
				preload="auto"
				className="absolute inset-0 size-full object-cover"
			/>
			<div className="absolute inset-0 bg-black opacity-50"></div>
			<Container
				className={clsx("isolate", {
					"!pt-layout2": breadcrumb,
				})}
			>
				{breadcrumb}
				<div className="mx-auto flex min-h-[400px] max-w-[1000px] flex-col justify-center text-center drop-shadow-lg">
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : null}

					{title ? (
						<Paragraph
							as="h1"
							size={titleSize ? titleSize : "heading-1"}
							fontWeight="font-semibold"
						>
							{title}
						</Paragraph>
					) : null}

					{description ? (
						typeof description === "string" ? (
							<Paragraph size="body-large" color="secondary" className="my-6">
								{description}
							</Paragraph>
						) : (
							<BlockContent
								value={description}
								blockOverrides={{
									h1: ({ children }) => (
										<Paragraph
											as="h1"
											size={titleSize ? titleSize : "heading-1"}
											fontWeight="font-semibold"
										>
											{children}
										</Paragraph>
									),
									normal: ({ children }) => (
										<Paragraph
											size="body-large"
											className="mt-6"
											color="secondary"
										>
											{children}
										</Paragraph>
									),
								}}
							/>
						)
					) : null}
					{hostProviders ? (
						<HostProvider
							logoItems={hostProviders}
							variant="compact"
							hideTitle={hideHostProviderTitle}
						/>
					) : null}

					{(primaryCTA && primaryCTA?.url) ||
					(secondaryCTA && secondaryCTA?.url) ? (
						<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center">
							<CtaButtonGroup
								primaryCTA={primaryCTA}
								secondaryCTA={secondaryCTA}
							/>
						</div>
					) : null}
				</div>
			</Container>
		</div>
	);
}

export function Hero({
	tagline,
	title,
	titleSize = "heading-1",
	description,
	image,
	videoHosting,
	wistiaId,
	ytId,
	primaryCTA,
	secondaryCTA,
	variant = "default",
	backgroundColor,
	backgroundImage,
	backgroundPosition,
	backgroundSize,
	backgroundVideo,
	hostProviders,
	hideHostProviderTitle,
	breadcrumb,
	imageSize = "default",
	heroHasVideo = false,
	roundedCorners,
	carouselItems,
	carouselSyncTitleItems,
	isTitleMultiColor = false,
}: HeroProps) {
	return (
		<div
			className={clsx(
				"overflow-hidden bg-theme-secondary bg-no-repeat",
				roundedCorners
			)}
			style={{
				backgroundColor: backgroundColor ?? undefined,
				backgroundImage: backgroundImage ?? undefined,
				backgroundPosition: backgroundPosition ?? undefined,
				backgroundSize: backgroundSize ?? undefined,
			}}
		>
			{variant === "compact" ? (
				<CompactHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					isTitleMultiColor={isTitleMultiColor}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
				/>
			) : null}
			{variant === "default" ? (
				<DefaultHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					isTitleMultiColor={isTitleMultiColor}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					heroHasVideo={heroHasVideo}
					imageSize={imageSize}
					videoHosting={videoHosting}
					wistiaId={wistiaId}
					ytId={ytId}
					image={image}
				/>
			) : null}
			{variant === "carousel" ? (
				<CarouselHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					carouselItems={carouselItems}
				/>
			) : null}
			{variant == "carouselSyncTitle" ? (
				<CarouselSyncTitleHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					carouselSyncTitleItems={carouselSyncTitleItems}
				/>
			) : null}
			{variant === "bgVideo" ? (
				<BgVideoHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					backgroundVideo={backgroundVideo}
				/>
			) : null}
		</div>
	);
}
