import React from "react";
import { useOnScreen } from "~/hooks/common";

export type VideoProps = React.VideoHTMLAttributes<HTMLVideoElement>;

export const Video: React.FC<VideoProps> = (props: VideoProps) => {
	const videoRef = React.useRef<HTMLVideoElement>(null);
	const isVisible = useOnScreen(videoRef, "-100px");

	React.useEffect(() => {
		const currentVideo = videoRef.current;
		if (!currentVideo) return;

		if (isVisible) {
			currentVideo.play();
		} else {
			currentVideo.pause();
		}
	}, [isVisible]);

	return <video ref={videoRef} {...props} />;
};

Video.displayName = "Video";
